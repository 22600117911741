import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from '../styles/bool.module.css';
import generic from '../styles/question.module.css';

const Question = ({ question, next, prev, answers, updateAnswer }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  const handleAnswerClick = async answerValue => {
    try {
      await updateAnswer(question.step, answerValue, question.var_name);
      setError(null);
      next(question.last_question, question.step, answerValue);
    } catch (err) {
      setError(t('form.update.error', 'Error updating answer'));
    }
  };
  const handlePrevClick = () => {
    setError(null);
    prev();
  };
  const middleIndex = Math.ceil(question.question_answers.length / 2);
  const firstHalfAnswers = question.question_answers.slice(0, middleIndex);
  const secondHalfAnswers = question.question_answers.slice(middleIndex);
  return (
    <div className={cn(styles.buttons)} role="group" aria-labelledby="question-label">
      <div id="question-label" className={cn(generic.question_label)}>
        {question.question_label}
      </div>
      <div className={cn(styles.buttonsContainer)}>
        <div className={cn(styles.buttonsContainer)}>
          {firstHalfAnswers.map((answer, index) => (
            <button
              key={`ans-${question.step}-${index}`}
              onClick={() => handleAnswerClick(answer.value)}
              className={cn(styles.consultButtons, answers[question.step] === answer.value && styles.selected, index === 0 && styles.first)}
              aria-selected={answers[question.step] === answer.value}>
              {answer.label}
            </button>
          ))}
        </div>
        {question.info && (
          <div className={cn(styles.info)} role="note">
            {question.info}
          </div>
        )}
        {question.suggestion && (
          <div className={cn(generic.suggestion)} role="note">
            {question.suggestion}
          </div>
        )}
        <div>
          {secondHalfAnswers.map((answer, index) => (
            <button
              key={`ans-${question.step}-${middleIndex + index}`}
              onClick={() => handleAnswerClick(answer.value)}
              className={cn(styles.consultButtons, answers[question.step] === answer.value && styles.selected)}
              aria-selected={answers[question.step] === answer.value}>
              {answer.label}
            </button>
          ))}
        </div>
        {error && (
          <div className={cn(styles.errorMessage)} role="alert" style={{ textAlign: 'center' }}>
            {error}
          </div>
        )}
      </div>
      <div className={cn(generic.stepButton)}>
        {question.step > 0 && (
          <button onClick={handlePrevClick} className={cn(generic.prevButton)} aria-label={t('form.previous', 'Previous question')}>
            {'<<'}
          </button>
        )}
      </div>
    </div>
  );
};
export default Question;
