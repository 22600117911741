'use client';
import React, { useEffect, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import moment from 'moment';
import Cookies from 'js-cookie';
import getQuestions from '../../api/feedback/getQuestions.js';
import addUserRequest from '../../api/request/addUserRequest.js';
import addRequest from '../../api/request/addRequest.js';
import updateRequest from '../../api/request/updateRequest.js';
import { updateAnswers, updateAnswersDebounced } from '../../api/request/updateAnswers.js';
import deleteRequest from '../../api/request/deleteUserRequest.js';
import useStorage from '../../hooks/useStorage.ts';
import mixpanel from 'mixpanel-browser';
import Stepper from './stepper';
import Question from './question';
import styles from './styles/StepQuestion.module.css';
import updateUserRequestUserInfo from '../../api/request/updateUserRequestUserInfo.js';
import { track } from '../../utilities/trackerStatistics.js';
import getIp from '../../api/getIp.js';
import ProgressStepper from './ProgressStepper';
import { usePathname, useSearchParams } from 'next/navigation';
const StepQuestions = props => {
  const { intro, title, description, onNextStepCallback = null } = props;
  const router = useRouter();
  const [stepStack, setStepStack] = useState([]);
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [timeOfRequest, setTimeOfRequest] = useState(moment());
  const [userRequestId, setUserRequestId] = useState(null);
  const [requestBody, setRequestBody] = useState({});
  const { getItem, getItems, setItem } = useStorage();
  const questions = getQuestions(getItem('questiontype', 'local'));
  useEffect(() => {
    if (router.query && router.query['start-step']) {
      setStep(Number(router.query['start-step']));
      // next(question.last_question, question.step);
    } else if (router.query && router.query['start-step-one']) {
      const question = questions.find(el => el.step === 0);
      next(question.last_question, question.step);
    }
  }, []);
  useEffect(() => {
    // retrieve values from storage
    const currentSteps = getItems('local');
    const answersFromSession = [];
    Object.keys(currentSteps).map(val => {
      if (val === 'currentRow') {
        // ricomincio a scrivere sulla richiesta precedente
        setCurrentRow(getItem(val, 'local'));
      }
      if (val.includes('step_')) {
        answersFromSession[Number(val.replaceAll('step_', ''))] = getItem(val, 'local');
      }

      // this is necessary to mantain the actual variable name and switch first two steps
      answersFromSession[0] = getItem('step_1', 'local');
      answersFromSession[1] = getItem('step_0', 'local');

      // recupero array di risposte
      setAnswers(answersFromSession);
      return true;
    });
    // se non ho info sul current row è una nuova richiesta
    if (!currentRow && !getItem('currentRow', 'local')) {
      //inizializzo il gsheet
      updateAnswers({ answers: ['Inizio il flusso'], time: timeOfRequest })
        .then(respAns => {
          // salvo la row del gsheet relativo al flusso
          setCurrentRow(respAns.row);
          setItem('currentRow', respAns.row, 'local');
        })
        .catch(err => {
          // console.log('error while updating answer', err);
        });
    }
  }, []);
  useEffect(() => {
    // Quando cambia answers aggiorno il gsheet
    const newAns = [].concat(answers);
    if (answers[1]) {
      newAns[0] = (answers[1] || '').replaceAll('.', '');
    }
    if (answers[0]) {
      newAns[1] = (answers[0] || '').replaceAll('.', '');
    }
    if (!answers[14] && answers[13]) {
      newAns[14] = '+39';
    }
    if (!answers[27] && answers[26]) {
      newAns[27] = '+39';
    }
    if (currentRow) {
      updateAnswersDebounced({ answers: newAns, row: currentRow, time: timeOfRequest });
    }
  }, [answers]);
  const calculatePayment = async data => {
    data.answerRow = currentRow;
    setItem('answerRow', currentRow, 'local');
    router.push(`/preventivi-mutuo/?show_chat=1`);
  };
  const getNextStep = (questions, currentAnswer) => {
    const current_question = questions.find(el => el.step === step);
    if (current_question.last_question) {
      return 'rate';
    }
    if (current_question.generate_lead && !getItem('reqId', 'local')) {
      return 'lead';
    }
    if (current_question.next_step) {
      return current_question.next_step;
    }
    for (const ans of current_question.question_answers) {
      if (ans.value === currentAnswer) {
        return ans.next_step;
      }
    }
  };
  // next step
  const next = async (isLast = false, currentStep = null, currentAnswer = null) => {
    const cookies = await Cookies.get();
    const useragent = window.navigator.userAgent;
    const ip = await getIp();
    const info = { cookies, useragent, ip };
    // console.log('cookies --->', cookies);
    // console.log('useragent --->', useragent);
    // console.log('userip --->', ip);
    if (onNextStepCallback) {
      await onNextStepCallback();
    }
    const newRequestBody = Object.assign({}, requestBody, {
      id: userRequestId || getItem(`reqId`, 'local'),
      type: 'mortgage',
      name: answers[10] || getItem(`step_10`, 'local') || '-',
      lastName: answers[11] || getItem(`step_11`, 'local') || '-',
      email: answers[12] || getItem(`step_12`, 'local') || answers[25] || getItem(`step_25`, 'local') || '',
      phone: answers[13] || getItem(`step_13`, 'local') || answers[26] || getItem(`step_26`, 'local') || '',
      prefix: answers[14] || getItem(`step_14`, 'local') || '+39',
      date: new Date(),
      toc: true,
      tocwa: (() => {
        if (typeof answers[17] !== 'undefined') {
          if (['SI', 'NO'].includes(answers[17])) {
            const local = getItem(`step_30`, 'local');
            if (local === 'true') return true;
            return false;
          }
        }
        const local = getItem(`step_17`, 'local');
        if (local === 'true') return true;
        return false;
      })(),
      message: '',
      product: {
        nomebanca: '-',
        rata: getItem(`validity`, 'local') ? getItem(`product_rata`, 'local') : '0',
        prodottoId: answers[13] || getItem(`step_13`, 'local') ? '0' : null,
      },
      estimatorData: {
        requestId: getItem('reqId', 'local') || null,
        tipologiaLavoro: answers[15] || getItem(`step_15`, 'local') || '',
        finalita: answers[5] || getItem(`step_5`, 'local') || '',
        tipoTasso: answers[3] || getItem(`step_3`, 'local') || '',
        durata: answers[4] || getItem(`step_4`, 'local') || '',
        eta: answers[2] || getItem(`step_2`, 'local') || '',
        citta: answers[7] || getItem(`step_7`, 'local') || '',
        importo: (answers[1] || getItem(`step_0`, 'local') || '').replaceAll('.', ''),
        valoreImmobile: (answers[0] || getItem(`step_1`, 'local') || '').replaceAll('.', ''),
        fromSource: 'preventivatore',
        search_status: (answers[6] || getItem(`step_6`, 'local') || '').replaceAll('.', ''),
        user: {
          name: answers[10] || getItem(`step_10`, 'local') || '-',
          lastName: answers[11] || getItem(`step_11`, 'local') || '-',
          email: answers[12] || getItem(`step_12`, 'local') || answers[25] || getItem(`step_25`, 'local') || '',
          phone: answers[13] || getItem(`step_13`, 'local') || answers[26] || getItem(`step_26`, 'local') || '',
          prefix: answers[14] || getItem(`step_14`, 'local') || '+39',
        },
        answerRow: answers[13] || getItem(`step_13`, 'local') ? currentRow : null,
      },
      answerRow: answers[13] || getItem(`step_13`, 'local') ? currentRow : null,
      info,
    });
    setRequestBody(newRequestBody);
    const next_step = isLast ? 'rate' : getNextStep(questions, currentAnswer);
    if (!userRequestId && !getItem('reqId', 'local')) {
      addUserRequest(newRequestBody)
        .then(resp => {
          // // console.log('new request success', resp);
          setUserRequestId(resp.id);
          // MIXPANEL nuovo flusso
          // mixpanel.track('inizio flusso', {
          //   user_req_id: resp.id,
          //   from: getItem('landedIn', 'local'),
          //   fromPage: getItem('landingFrom', 'local'),
          // });
          track('inizio flusso', {
            user_req_id: resp.id,
            from: getItem('landedIn', 'local'),
            fromPage: getItem('landingFrom', 'local'),
          });
          let val = getItem(`step_0`, 'local');
          val = Number(val.replaceAll('.', ''));
          if (val >= 250000) {
            val = 'Alto';
          } else if (val < 250000 && val >= 150000) {
            val = 'Medio';
          } else {
            val = 'Basso';
          }
          // mixpanel.track('1 - importo mutuo', {
          //   answer: val,
          //   current_step: 0,
          //   user_req_id: resp.id,
          // });
          track('1 - importo mutuo', {
            answer: val,
            current_step: 0,
            user_req_id: resp.id,
          });
          setItem('userRequestId', resp.id, 'local');
        })
        .catch(err => {
          // console.log('error addRequest', err);
        });
    } else if (!isLast && next_step !== 'lead') {
      // // console.log("qua modifico, lo faccio sempre tranne che all'inizio e agli ultimi due step");
      updateRequest(newRequestBody)
        .then(resp => {
          const current_step = step ? step : 0;
          // MIXPANEL calcola rata
          let val = getItem(`step_${current_step}`, 'local');
          if (current_step === 0 || current_step === 1) {
            val = Number(val.replaceAll('.', ''));
            if (val >= 250000) {
              val = 'Alto';
            } else if (val < 250000 && val >= 150000) {
              val = 'Medio';
            } else {
              val = 'Basso';
            }
          }
          if (current_step === 2) {
            // età
            if (val === '34') {
              val = 'meno di 36';
            } else {
              val = 'più di 36';
            }
          }
          let event_name = `step_${current_step}`;
          switch (current_step) {
            case 0:
              event_name = '1 - importo mutuo';
              break;
            case 1:
              event_name = '2 - valore immobile';
              break;
            case 2:
              event_name = '3 - età';
              break;
            case 3:
              event_name = '4 - tasso';
              break;
            case 4:
              event_name = '5 - durata';
              break;
            case 5:
              event_name = '6 - finalità';
              break;
            case 6:
              event_name = '7 - avanzamento ricerca';
              break;
            case 7:
              event_name = '8 - comune immobile';
              break;
            case 8:
              event_name = '9 - approfondimento idoneità';
              break;
            case 9:
              event_name = '10 - offerta consulenza';
              break;
            // DOMANDA FLUSSO NUOVO
            case 17:
              event_name = '12 - offerta verifica fattibilità open';
              break;
            case 18:
              event_name = 'verifica fattibilità open';
              break;
            case 19:
              event_name = '2. verifica fattibilità open - intestatari';
              break;
            case 20:
              event_name = '3. verifica fattibilità open - reddito';
              break;
            case 21:
              event_name = '3. verifica fattibilità open - reddito';
              break;
            case 22:
              event_name = '4. verifica fattibilità open - provenienza redditi';
              break;
            default:
              event_name = `step_${current_step}`;
          }
          if (current_step === 1) {
            window.dataLayer.push({
              event: 'display_learning',
            });
          }
          if (current_step === 18) {
            window.dataLayer.push({
              event: 'verifica_fattibilita_open',
            });
          }
          if (current_step === 7) {
            // mixpanel.track(event_name, {
            //   answer: val,
            //   regione: getItem(`step_${current_step}_regione`, 'local'),
            //   provincia: getItem(`step_${current_step}_provincia`, 'local'),
            //   current_step: current_step,
            //   user_req_id: getItem(`userRequestId`, 'local'),
            // });
            track(event_name, {
              answer: val,
              regione: getItem(`step_${current_step}_regione`, 'local'),
              provincia: getItem(`step_${current_step}_provincia`, 'local'),
              current_step: current_step,
              user_req_id: getItem(`userRequestId`, 'local'),
            });
          } else {
            // mixpanel.track(event_name, {
            //   answer: val,
            //   current_step: current_step,
            //   user_req_id: getItem(`userRequestId`, 'local'),
            // });
            track(event_name, {
              answer: val,
              current_step: current_step,
              user_req_id: getItem(`userRequestId`, 'local'),
            });
            if (
              (getItem(`questiontype`, 'local') !== '1' && current_step === 9 && val === 'SI') ||
              (getItem(`questiontype`, 'local') === '1' && current_step === 8 && val === 'SI')
            ) {
              setTimeout(
                () =>
                  //mixpanel.track('11 - visualizzazione consulenza'),
                  track('11 - visualizzazione consulenza'),
                300,
              );
            }
            // GTM EVENT TO DECODE AB FLUX
            if (getItem(`questiontype`, 'local') === '1' && current_step === 0) {
              window.dataLayer.push({
                event: 'questiontype_1',
              });
            }
            if (current_step === 22 && !getItem('reqId', 'local')) {
              setTimeout(
                () =>
                  // mixpanel.track('5. verifica fattibilità open - form lead')
                  track('5. verifica fattibilità open - form lead'),
                300,
              );
            }
            if (current_step === 23) {
              setTimeout(
                () =>
                  // mixpanel.track('6. verifica fattibilità open - visualizzazione risultati')
                  track('6. verifica fattibilità open - visualizzazione risultati'),
                300,
              );
            }
          }
        })
        .catch(err => {
          // console.log('error updateRequest', err);
        });
    }
    if (next_step) {
      const current_step = step ? step : 0;
      switch (next_step) {
        case 'lead':
          // api to get list of results
          // console.log('UTK:', document?.cookie?.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1'));
          // console.log('CREO LA NUOVA LEAD', current_step);
          return addRequest({ ...newRequestBody, hb_utk: document?.cookie?.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1') || '' })
            .then(resp => {
              // console.log('resp', resp);
              // SET REQID (resp.id) da usare per appendere le verifica fattibilità
              newRequestBody.estimatorData.reqid = resp.id;
              setItem('reqId', resp.id, 'local');
              if (Number(current_step) === 23) {
                // mixpanel.track(`lead`, {
                //   type: 'verifica fattibilità open',
                //   lead_name: answers[10] || getItem(`step_10`, 'local') || '-',
                //   lead_lastName: answers[11] || getItem(`step_11`, 'local') || '-',
                //   lead_email: answers[12] || getItem(`step_12`, 'local') || '',
                //   lead_phone: answers[13] || getItem(`step_13`, 'local') || '',
                //   lead_prefix: answers[14] || getItem(`step_14`, 'local') || '+39',
                //   current_step: 'lead',
                //   user_req_id: getItem(`userRequestId`, 'local'),
                //   lead_req_id: resp.id,
                // });
                track(`lead`, {
                  type: 'verifica fattibilità open',
                  lead_name: answers[10] || getItem(`step_10`, 'local') || '-',
                  lead_lastName: answers[11] || getItem(`step_11`, 'local') || '-',
                  lead_email: answers[12] || getItem(`step_12`, 'local') || '',
                  lead_phone: answers[13] || getItem(`step_13`, 'local') || '',
                  lead_prefix: answers[14] || getItem(`step_14`, 'local') || '+39',
                  current_step: 'lead',
                  user_req_id: getItem(`userRequestId`, 'local'),
                  lead_req_id: resp.id,
                });
              } else {
                // mixpanel.track(`lead`, {
                //   type: 'consulenza',
                //   lead_name: answers[10] || getItem(`step_10`, 'local') || '-',
                //   lead_lastName: answers[11] || getItem(`step_11`, 'local') || '-',
                //   lead_email: answers[12] || getItem(`step_12`, 'local') || '',
                //   lead_phone: answers[13] || getItem(`step_13`, 'local') || '',
                //   lead_prefix: answers[14] || getItem(`step_14`, 'local') || '+39',
                //   current_step: 'lead',
                //   user_req_id: getItem(`userRequestId`, 'local'),
                //   lead_req_id: resp.id,
                // });
                track(`lead`, {
                  type: 'consulenza',
                  lead_name: answers[10] || getItem(`step_10`, 'local') || '-',
                  lead_lastName: answers[11] || getItem(`step_11`, 'local') || '-',
                  lead_email: answers[12] || getItem(`step_12`, 'local') || '',
                  lead_phone: answers[13] || getItem(`step_13`, 'local') || '',
                  lead_prefix: answers[14] || getItem(`step_14`, 'local') || '+39',
                  current_step: 'lead',
                  user_req_id: getItem(`userRequestId`, 'local'),
                  lead_req_id: resp.id,
                });
              }
              return newRequestBody;
            })
            .then(data => {
              deleteRequest(data.id);
              setStep(step + 1);
              setStepStack([...stepStack, step]);
            })
            .catch(err => {
              // console.log('error addRequest', err);
            });
        // if (!getItem('reqId', 'local')) {
        // } else {
        //   return null;
        // }
        case 'rate':
          // VADO ALLA LISTA DEI RISULTATI (NON HO UNA LEAD!)
          calculatePayment(newRequestBody.estimatorData);
          return null;
        case 'dati':
          setStep(10);
          setStepStack([...stepStack, 10]);
          break;
        default:
          setStep(next_step);
          setStepStack([...stepStack, step]);
          break;
      }
    } else {
      setStep(step + 1);
      setStepStack([...stepStack, step]);
    }
  };
  // chiedo il prossimo step
  const getPreviousStep = () => {
    if (stepStack.length === 0) {
      return 0;
    }
    let prevStep = stepStack[stepStack.length - 1];
    setStepStack(stepStack.slice(0, stepStack.length - 1));
    return prevStep;
  };
  // prev step
  const prev = () => {
    let prevStep = getPreviousStep();
    if (prevStep) {
      setStep(prevStep);
    } else {
      setStep(Math.min(0, step - 1));
    }
  };
  // update answer viene richiamata dalle questions per aggiornare l'array delle risposte
  const updateAnswer = (index, answer, var_name = null) => {
    const currAnswers = [].concat(answers);
    // MAP LEAD NEW TO OLD
    switch (index) {
      case 23: {
        currAnswers[10] = answer;
        currAnswers[index] = answer;
        break;
      }
      case 24: {
        currAnswers[11] = answer;
        currAnswers[index] = answer;
        break;
      }
      case 25: {
        currAnswers[12] = answer;
        currAnswers[index] = answer;
        break;
      }
      case 26: {
        currAnswers[13] = answer;
        currAnswers[index] = answer;
        break;
      }
      case 27: {
        currAnswers[14] = answer;
        currAnswers[index] = answer;
        break;
      }
      case 29: {
        currAnswers[16] = answer;
        currAnswers[index] = answer;
        break;
      }
      default:
        currAnswers[index] = answer;
    }
    const reqId = userRequestId || getItem(`userRequestId`, 'local');
    if (reqId) {
      // aggiorno il modello UserRequest lato db con le info dell'utente
      // updateUserRequestUserInfoByAnswerArray(reqId, index, answer, currentRow);
      const userInfo = {
        name: currAnswers[10] || getItem(`step_10`, 'local') || '',
        lastName: currAnswers[11] || getItem(`step_11`, 'local') || '',
        email: currAnswers[12] || getItem(`step_12`, 'local') || '',
        phone: currAnswers[13] || getItem(`step_13`, 'local') || '',
        prefix: currAnswers[14] || getItem(`step_14`, 'local') || '',
      };
      updateUserRequestUserInfo(reqId, userInfo, currentRow);
    }
    if (!var_name) {
      setItem(`step_${index}`, answer, 'local');
    } else {
      setItem(var_name, answer, 'local');
    }
    setAnswers(currAnswers);
  };
  const { t } = useTranslation();
  const current_question = questions.find(el => el.step === step);

  const pathname = usePathname();
  const searchParams = useSearchParams();
  const stepParam = router?.query?.position;

  const stepMap = [
    { param: 'valore-casa', value: 0 },
    { param: 'importo-mutuo', value: 1 },
    { param: 'eta', value: 2 },
    { param: 'tasso', value: 3 },
    { param: 'durata', value: 4 },
    { param: 'finalita', value: 5 },
    { param: 'ricerca', value: 6 },
    { param: 'comune', value: 7 },
    { param: 'idoneita', value: 8 },
    { param: 'consulenza-gratuita', value: 9 },
    { param: 'form-a', value: 10 },
    { param: 'lead-consulenza', value: 11 },
    { param: 'fattibilita-open', value: 17 },
    { param: 'fattibilita-open-intro', value: 18 },
    { param: 'intestatari-open', value: 19 },
    { param: 'reddito-open', value: 20 },
    { param: 'reddito-open-multi', value: 21 },
    { param: 'provenienza-open', value: 22 },
    { param: 'form-b', value: 23 },
    { param: 'lead-fattibilita-open', value: 24 },
  ];

  useEffect(() => {
    console.log('CURRENTPOSITION', stepParam);
    const step_value = stepMap.find(({ param }) => param === stepParam)?.value;
    if (step_value === undefined) {
      setStep(0);
    } else {
      setStep(step_value);
    }
  }, [stepParam]);

  function appendQueryString(paramName, paramValue) {
    const params = new URLSearchParams(router.query);
    // console.log('router', router.query);
    if (params.get(paramName) !== paramValue) {
      params.set(paramName, paramValue);
      router.push(pathname + '?' + params.toString());
    }
  }

  useEffect(() => {
    const step_name = stepMap.find(({ value }) => value === step)?.param || step?.toString();
    appendQueryString('position', step_name);
  }, [step]);

  // console.log(current_question.stepper_step)
  const getCurrentStepperStep = () => {
    return current_question?.stepper_step || 1;
  };
  const shouldShowPercentage = current_question?.show_percentage;
  const shouldShowProgressStepper = !shouldShowPercentage;

  return (
    <div className={cn(styles.stepQuestionsContainer)}>
      {questions && questions.length ? (
        <>
          {shouldShowProgressStepper &&
            !['lead_verify_form', 'lead', 'lead_verify_result', 'contactRequestSent'].includes(current_question.question_type) && (
              <ProgressStepper currentStep={getCurrentStepperStep()} />
            )}
          {intro && step < 1 ? <div className="step-page-intro">{intro}</div> : null}
          {title && step < 1 ? <div className={styles.titleText}>{title}</div> : null}
          {shouldShowPercentage ? <Stepper question={current_question} intro={intro} total={questions.length - 1} current={step + 1} /> : null}

          {description && step < 1 ? <div className={styles.descriptionText}>{description}</div> : null}
          <Question question={current_question} next={next} prev={prev} answers={answers} updateAnswer={updateAnswer} requestBody={requestBody} />
        </>
      ) : null}
    </div>
  );
};
export default StepQuestions;
