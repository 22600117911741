import config from "../config/config.js";

const { baseUrl } = config;

/* *********************
  email
  password
********************** */

export default function getBanche() {
  const endpoint = `${baseUrl}/v1/preventivo/banche`;
  const dataToSend = {
    autorization: {
      username: "oltre.it",
      password: "HHjDY46dhV57Xm3BLQHRZG",
    },
  }
  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `tassomutuo.it`,
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify(dataToSend),
  };

  return fetch(endpoint, options)
    .then((response) => {
      if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        throw new Error();
      }
      return response.json();
    })
    .catch((e) => {
      throw new Error(JSON.stringify(result));
    });
}
