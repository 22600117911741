import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from '../styles/buttons.module.css';
import generic from '../styles/question.module.css';
const Question = ({ question, next, prev, answers, updateAnswer }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const handleValidation = () => {
    if (question.required && !answers[question.step]) {
      setError(t('form.select.answer', 'Seleziona una risposta'));
      return false;
    }
    setError(null);
    return true;
  };
  const handleAnswerSelect = answerValue => {
    updateAnswer(question.step, answerValue, question.var_name);
    setError(null);
    next(question.last_question, question.step, answerValue);
  };
  const handleNextClick = () => {
    if (handleValidation()) {
      next(question.last_question, question.step, answers[question.step]);
    }
  };
  const handlePrevClick = () => {
    setError(null);
    prev();
  };
  return (
    <div className={cn(styles.buttons)} role="group" aria-labelledby="question-label">
      <div id={`question-label${question.var_name}`} className={cn(generic.question_label)}>
        {question.question_label}
      </div>
      <div className={cn(styles.answersErrorContainer)}>
        <div className={cn(styles.answersContainer)}>
          {question.question_answers.map((answer, index) => (
            <button
              key={`question-${question.step}-answer-${index}`}
              onClick={() => handleAnswerSelect(answer.value)}
              className={cn(styles.button, answers[question.step] === answer.value && styles.selected)}
              aria-selected={answers[question.step] === answer.value}>
              {answer.img && (
                <img
                  src={`/${answer.img}`}
                  alt=""
                  title={answer.label}
                  style={{
                    marginRight: '8px',
                    width: '25px',
                    verticalAlign: 'middle',
                  }}
                />
              )}
              <span>{answer.label}</span>
            </button>
          ))}
        </div>
        {error && (
          <div className={cn(styles.errorMessage)} role="alert">
            {error}
          </div>
        )}
      </div>
      {question.suggestion && (
        <div id={`question-suggestion${question.var_name}`} className={cn(generic.suggestion)} role="note">
          {question.suggestion}
        </div>
      )}
      <div className={cn(generic.stepButton)}>
        {question.step > 0 && (
          <button onClick={handlePrevClick} className={cn(generic.prevButton)} aria-label={t('form.previous', 'Previous question')}>
            {'<<'}
          </button>
        )}
        <button
          onClick={handleNextClick}
          className={cn(generic.nextButton, {
            [generic.disabledButton]: !answers[question.step],
          })}
          aria-label={t('form.next', 'Next question')}
          disabled={!answers[question.step]}>
          {t('form.next.label', 'AVANTI')}
          <span className={cn(generic.nextButtonCross)} aria-hidden="true">
            {'>>'}
          </span>
        </button>
      </div>
    </div>
  );
};
export default Question;
