import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles/question.module.css';
import Select from './questionsType/select.js';
import Buttons from './questionsType/buttons.js';
import Price from './questionsType/price.js';
import Intro from './questionsType/intro.js';
import Bool from './questionsType/bool.js';
import Percentage from './questionsType/percentage.js';
import City from './questionsType/city.js';
import Lead from './questionsType/lead.js';
import LeadVerify from './questionsType/lead_verify.js';
import LeadVerifyForm from './questionsType/lead_verify_form.js';
import LeadVerifyResult from './questionsType/lead_verify_result.js';
import IntroVerify from './questionsType/intro_verify.js';
import ContactRequestSent from './questionsType/ContactRequestSent.js';
import loanConsultationStep from './questionsType/loanConsultationStep.js';
import redditoPrice from './questionsType/redditoPrice.js';
import ContrattoBtns from './questionsType/ContrattoBtns.js';

const componentMap = {
  select: Select,
  intro: Intro,
  buttons: Buttons,
  price: Price,
  percentage: Percentage,
  bool: Bool,
  city: City,
  loanConsultationStep: loanConsultationStep,
  redditoPrice: redditoPrice,
  ContrattoBtns: ContrattoBtns,
  lead: Lead,
  lead_verify: LeadVerify,
  lead_verify_form: LeadVerifyForm,
  lead_verify_result: LeadVerifyResult,
  intro_verify: IntroVerify,
  contactRequestSent: ContactRequestSent,
};
const Question = ({ question, next, prev, answers, updateAnswer, requestBody = null }) => {
  const { t } = useTranslation();
  const QuestionComponent = componentMap[question.question_type];
  if (!QuestionComponent) {
    console.warn(`Unknown question type: ${question.question_type}`);
    return null;
  }
  return (
    <div id={`container_${question.var_name}`} key={`question_container_step_${question.step}`} className={styles.question}>
      <div className={styles.label}>
        <QuestionComponent
          key={`question_${question.question_type}_step_${question.step}`}
          question={question}
          next={next}
          prev={prev}
          answers={answers}
          updateAnswer={updateAnswer}
          {...(requestBody && { requestBody })}
        />
      </div>
    </div>
  );
};
export default Question;
