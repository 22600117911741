import styles from './styles/StepQuestion.module.css';
const ProgressStepper = ({ currentStep, stepperStep, showStepper }) => {
  const steps = ['Inizio', 'Intestatari', 'Reddito', 'Contratto'];
  // console.log( currentStep)
  return (
    <div className={styles.stepperContainer}>
      <div className={styles.stepperWrapper}>
        <div className={styles.progressLine} />
        <div
          className={styles.progressLineActive}
          style={{
            width: `calc(${((currentStep - 1) / (steps.length - 1)) * 100}% - 32px)`,
          }}
        />
        {steps.map((stepLabel, index) => (
          <div key={index} className={styles.step}>
            <div className={`${styles.stepCircle} ${index + 1 <= currentStep ? styles.stepCircleActive : ''}`} />
            <span className={`${styles.stepLabel} ${index + 1 <= currentStep ? styles.stepLabelActive : ''}`}>{stepLabel}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ProgressStepper;
